body {
  margin: 0;
  font-family: 'Source Sans Pro';
}


body {
  background-color: #f6f6f6;
  line-height: normal;
}
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
